import React from "react"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import Helmet from "react-helmet"

import { useI18n, LocaleLink as Link } from "../../contexts/i18n"
import Layout from "../../layout"
import SEO from "../../components/SEO"
import { moviePath, showPath } from "../../../routes"

// @ts-ignore
import * as styles from "./index.module.scss"

interface DataType {
  promoted: Contentful.List<Contentful.Movie>
  movies: Contentful.Cxn<Contentful.Movie>
  shows: Contentful.Cxn<Contentful.Show>
  shortFilms: Contentful.Cxn<Contentful.Movie>
}

const MoviesPage = ({ data }: PageProps<DataType>) => {
  const { translate: t } = useI18n(`movies`)

  const movies = data.movies.edges.map(({ node }) => node)
  const shows = data.shows.edges.map(({ node }) => node)

  const flicks = [...movies, ...shows].sort(reverseByDate)

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: styles.moviesPage }} />
      <SEO title={t`Movies`} />
      {data.promoted.items && (
        <Slider autoplay autoplaySpeed={5000} dots draggable centerMode infinite swipeToSlide arrows slidesToShow={1}>
          {data.promoted.items.map((movie) => (
            <div className={styles.sliderContainer}>
              <Movie {...movie} path={pathTo(movie)} />
            </div>
          ))}
        </Slider>
      )}
      <GenreSlider title={t`Upcoming Movies`} items={flicks.filter((f) => f.status === `Coming Soon`)} />
      <GenreSlider title={t`GCFlix Original`} items={flicks.filter((f) => f.gcFlixOriginal)} />
      <GenreSlider title={t`Shows`} items={shows} />
      <GenreSlider title={t`Action`} items={flicks.filter(byGenre(`Action`))} />
      <GenreSlider title={t`Comedy`} items={flicks.filter(byGenre(`Comedy`))} />
      <GenreSlider title={t`Documentary`} items={flicks.filter(byGenre(`Documentary`))} />
      <GenreSlider title={t`Drama`} items={flicks.filter(byGenre(`Drama`))} />
      <GenreSlider title={t`Family`} items={flicks.filter(byGenre(`Family`))} />
      <GenreSlider title={t`Horror`} items={flicks.filter(byGenre(`Horror`))} />
      <GenreSlider title={t`Thriller`} items={flicks.filter(byGenre(`Thriller`))} />
      <GenreSlider title={t`Short Films`} items={data.shortFilms.edges.map(({ node }) => node)} />
    </Layout>
  )

  function reverseByDate<T>(a: { createdAt: T }, b: { createdAt: T }) {
    if (a.createdAt > b.createdAt) return -1
    if (a.createdAt < b.createdAt) return 1
    return 0
  }

  function byGenre<T>(genre: T) {
    return (f: { genre: T[] }) => f.genre.includes(genre)
  }
}

export default MoviesPage

export const pageQuery = graphql`
  query ($locale: String!) {
    promoted: contentfulList(title: { eq: "Promoted Movies" }, node_locale: { eq: $locale }) {
      items {
        __typename
        ... on ContentfulMovie {
          slug
          title
          posterImageHorizontal {
            gatsbyImage: gatsbyImageData(width: 1200)
          }
        }
        ... on ContentfulShow {
          slug
          title
          posterImageHorizontal {
            gatsbyImage: gatsbyImageData(width: 1200)
          }
        }
      }
    }
    movies: allContentfulMovie(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          __typename
          status
          gcFlixOriginal
          genre
          slug
          title
          createdAt
          posterImageHorizontal {
            gatsbyImage: gatsbyImageData(width: 250)
          }
        }
      }
    }
    shows: allContentfulShow(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          __typename
          gcFlixOriginal
          genre
          slug
          title
          createdAt
          posterImageHorizontal {
            gatsbyImage: gatsbyImageData(width: 250)
          }
        }
      }
    }
    shortFilms: allContentfulMovie(filter: { node_locale: { eq: $locale }, type: { in: "Short Film" } }) {
      edges {
        node {
          __typename
          slug
          title
          posterImageHorizontal {
            gatsbyImage: gatsbyImageData(width: 250)
          }
        }
      }
    }
  }
`

interface MovieProps {
  title: string
  posterImageHorizontal: Contentful.Asset
  path: string
}

const Movie = ({ title, posterImageHorizontal, path }: MovieProps) => (
  <Link to={path} title={title} className="movie-card-link">
    <div className="movie-card">
      <GatsbyImage alt={title} image={posterImageHorizontal.gatsbyImage} />
      <h3 className="movie-title">{title}</h3>
    </div>
  </Link>
)

interface GenreSliderProps {
  title: string
  items: (Contentful.Movie | Contentful.Show)[]
}

const GenreSlider = ({ title, items }: GenreSliderProps) => {
  if (items.length === 0) return <></>

  const settings = {
    slidesToShow: 4,
    infinite: items.length > 4,
    swipeToSlide: true,
    centerMode: items.length > 6,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          centerMode: items.length > 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: items.length > 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          centerMode: items.length > 3,
        },
      },
    ],
  }

  return (
    <div className={styles.sliderTrack}>
      <h2 className={styles.sliderHeading}>{title}</h2>
      <Slider {...settings}>
        {items.map((item) => (
          <div key={item.slug} className={styles.sliderContainer}>
            <Movie {...item} path={pathTo(item)} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

function pathTo(item: Contentful.Movie | Contentful.Show) {
  switch (item.__typename) {
    case "ContentfulShow":
      return showPath(item.slug)

    case "ContentfulMovie":
    default:
      return moviePath(item.slug)
  }
}
